import React, { useEffect } from 'react';
import Layout from 'components/LandingLayout';
import SEO from 'components/seo';
import 'animate.css/animate.css';

import { UNBOUNCE_URL, windowGlobal } from '../config';
import { WithTrans } from '../language/withTrans';
import metaTranslations from '../lib/utils';
import { getEventProviderInstance } from '../appEvents';
import { EventProviders } from '../constants/events';

const IndexPage = ({ lang }) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  useEffect(() => {
    const ReactPixel = getEventProviderInstance(EventProviders.FB_PIXEL);

    if (ReactPixel) {
      ReactPixel.pageView();
    }

    import('wowjs')
      .then(({ default: library }) => {
        // eslint-disable-next-line no-new
        new library.WOW({
          live: false,
          animateClass: 'animate__animated',
          mobile: false,
        }).init();
      });
  }, []);

  // const handleOnEnterSection = useCallback((section) => {
  //   setTimeout(() => {
  //     EventReporter.action(AppEvents.LANDING_SCROLL({ section }), [EventProviders.MIXPANEL]);
  //   }, 1000);
  // }, []);

  // const Sections = {
  //   HERO: 'landing_hero',
  //   MISSION: 'mission',
  //   HIGHLIGHTS: 'highlights',
  //   TESTIMONIALS: 'use_cases',
  //   CTA_LOGIN_FORM: 'cta_login_form',
  //   BENEFITS: 'benefits',
  //   GET_STARTED: 'get_started',
  //   PARTNERS: 'partners',
  //   FOOTER: 'footer',
  // };

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      <iframe id="iframe-unbounce" src={UNBOUNCE_URL} frameborder="0"></iframe>
    </Layout>
  );
};

export default WithTrans()(IndexPage);
